import { IChatUser } from '../models/ChatUser';
import { IWorkspace, IWorkspaceBranding } from '../models/Workspace';
import { IWorkspaceMember } from '../models/WorkspaceMember';
import { BaseService } from './BaseService';

export class WorkspaceService extends BaseService {
    public getWorkspaceAsync = async (workspaceId: string, accessToken: string): Promise<IWorkspace> => {
        const result = await this.getResponseAsync<IWorkspace>(
            {
                commandPath: `workspace/getWorkspace/${workspaceId}`,
                method: 'GET',
            },
            accessToken,
        );

        return result;
    };

    public getMyWorkspacesAsync = async (accessToken: string): Promise<IWorkspace[]> => {
        const result = await this.getResponseAsync<IWorkspace[]>(
            {
                commandPath: 'workspace/getMyWorkspaces',
                method: 'GET',
            },
            accessToken,
        );

        return result;
    };

    public getAllWorkspacesAsync = async (accessToken: string): Promise<IWorkspace[]> => {
        const result = await this.getResponseAsync<IWorkspace[]>(
            {
                commandPath: 'workspace/getAllWorkspaces',
                method: 'GET',
            },
            accessToken,
        );

        return result;
    };

    public getAllWorkspaceMembersAsync = async (workspaceId: string, accessToken: string): Promise<IChatUser[]> => {
        const result = await this.getResponseAsync<IWorkspaceMember[]>(
            {
                commandPath: `workspace/getAllMembers/${workspaceId}`,
                method: 'GET',
            },
            accessToken,
        );

        const workspaceMembers = result.map<IChatUser>((member) => ({
            id: member.userId,
            online: false,
            fullName: '', // The user's full name is not returned from the server
            emailAddress: '', // The user's email address is not returned from the server
            isTyping: false,
            photo: '',
        }));

        return workspaceMembers;
    };

    public joinWorkspaceAsync = async (workspaceId: string, accessToken: string): Promise<IWorkspace> => {
        return await this.getResponseAsync<IWorkspace>(
            {
                commandPath: `workspace/join/${workspaceId}`,
                method: 'POST',
            },
            accessToken,
        );
    };

    public createWorkspaceAsync = async (
        name: string,
        branding: IWorkspaceBranding,
        accessToken: string,
    ): Promise<IWorkspace> => {
        const body = {
            name,
            branding,
        };

        const result = await this.getResponseAsync<IWorkspace>(
            {
                commandPath: 'workspace/create',
                method: 'POST',
                body,
            },
            accessToken,
        );

        return result;
    };

    public editWorkspaceAsync = async (
        workspaceId: string,
        name: string,
        branding: IWorkspaceBranding,
        accessToken: string,
    ): Promise<IWorkspace> => {
        const body = {
            id: workspaceId,
            name,
            branding,
        };

        const result = await this.getResponseAsync<IWorkspace>(
            {
                commandPath: 'workspace/edit',
                method: 'POST',
                body,
            },
            accessToken,
        );

        return result;
    };
}
