import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IWorkspace } from '../../../libs/models/Workspace';
import { initialState, Workspaces, WorkspacesState } from './WorkspacesState';

const workspacesSlice = createSlice({
    name: 'workspaces',
    initialState,
    reducers: {
        addWorkspace: (state: WorkspacesState, action: PayloadAction<IWorkspace>) => {
            if (!state.workspaces.hasOwnProperty(action.payload.id)) {
                state.workspaces[action.payload.id] = action.payload;
            }

            workspacesSlice.caseReducers.setSelectedWorkspace(state, {
                type: 'workspaces/setSelectedWorkspace',
                payload: action.payload.id,
            });
        },
        setWorkspaces: (state: WorkspacesState, action: PayloadAction<Workspaces>) => {
            state.workspaces = action.payload;
            const workspaceIds = Object.keys(action.payload);

            if (!(state.selectedId && workspaceIds.includes(state.selectedId))) {
                workspacesSlice.caseReducers.setSelectedWorkspace(state, {
                    type: 'workspaces/setSelectedWorkspace',
                    payload: workspaceIds[0],
                });
            }
        },
        setSelectedWorkspace: (state: WorkspacesState, action: PayloadAction<string>) => {
            const workspace = state.workspaces[action.payload];

            if (workspace !== undefined) {
                localStorage.setItem('X-WorkspaceId', workspace.id);
                localStorage.setItem('chatcp-workspace', JSON.stringify(workspace));

                state.selectedId = action.payload;
            }
        },
    },
});

export const { addWorkspace, setWorkspaces, setSelectedWorkspace } = workspacesSlice.actions;

export default workspacesSlice.reducer;
