import { useMsal } from '@azure/msal-react';
import { useAppDispatch } from '../../redux/app/hooks';
import { addAlert } from '../../redux/features/app/appSlice';
import { Workspaces } from '../../redux/features/workspace/WorkspacesState';
import { addWorkspace, setWorkspaces } from '../../redux/features/workspace/workspacesSlice';
import { AuthHelper } from '../auth/AuthHelper';
import { AlertType } from '../models/AlertType';
import { IWorkspaceBranding } from '../models/Workspace';
import { WorkspaceService } from '../services/WorkspaceService';

export const useWorkspace = () => {
    const dispatch = useAppDispatch();
    const { instance, inProgress } = useMsal();

    const workspaceService = new WorkspaceService();

    const loadWorkspaces = async () => {
        try {
            const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
            const workspaces = await workspaceService.getMyWorkspacesAsync(accessToken);
            const normalisedWorkspaces = workspaces.reduce<Workspaces>(
                (acc, workspace) => ((acc[workspace.id] = workspace), acc),
                {},
            );

            if (workspaces.length === 0) {
                return false;
            }

            dispatch(setWorkspaces(normalisedWorkspaces));

            return true;
        } catch (e: any) {
            const errorMessage = `Unable to load workspaces. Details: ${getErrorDetails(e)}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));

            return false;
        }
    };

    const joinWorkspace = async (workspaceId: string) => {
        try {
            const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
            const workspace = await workspaceService.joinWorkspaceAsync(workspaceId, accessToken);

            dispatch(addWorkspace(workspace));
        } catch (e: any) {
            const errorDetails = getErrorDetails(e);

            if (errorDetails !== 'Error: 400: Bad Request => User is already in the workspace.') {
                const errorMessage = `Error joining workspace ${workspaceId}. Details: ${errorDetails}`;
                return { success: false, message: errorMessage };
            }
        }

        return { success: true, message: '' };
    };

    const getAllWorkspaces = async () => {
        try {
            const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);

            return await workspaceService.getAllWorkspacesAsync(accessToken);
        } catch (e: any) {
            const errorMessage = `Unable to fetch workspaces. Details: ${getErrorDetails(e)}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));

            return [];
        }
    };

    const createWorkspace = async (workspaceName: string, branding: IWorkspaceBranding) => {
        try {
            const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
            const workspace = await workspaceService.createWorkspaceAsync(workspaceName, branding, accessToken);

            dispatch(addWorkspace(workspace));
            return { success: true, result: workspace };
        } catch (e: any) {
            const errorMessage = `Unable to create workspace. Details: ${getErrorDetails(e)}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));

            return { success: false, message: errorMessage };
        }
    };

    const editWorkspace = async (workspaceId: string, workspaceName: string, branding: IWorkspaceBranding) => {
        try {
            const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
            const workspace = await workspaceService.editWorkspaceAsync(
                workspaceId,
                workspaceName,
                branding,
                accessToken,
            );

            // TODO: Update workspace in state
            return { success: true, result: workspace };
        } catch (e: any) {
            const errorMessage = `Unable to edit workspace. Details: ${getErrorDetails(e)}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));

            return { success: false, message: errorMessage };
        }
    };

    return {
        loadWorkspaces,
        joinWorkspace,
        getAllWorkspaces,
        createWorkspace,
        editWorkspace,
    };
};

function getErrorDetails(e: any) {
    return e instanceof Error ? e.message.trim() : String(e).trim();
}
