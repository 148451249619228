import { IWorkspace } from '../../../libs/models/Workspace';

export type Workspaces = Record<string, IWorkspace | undefined>;

export interface WorkspacesState {
    workspaces: Workspaces;
    selectedId: string;
}

const workspaceJson = localStorage.getItem('chatcp-workspace');
const localWorkspace = workspaceJson ? (JSON.parse(workspaceJson) as IWorkspace) : undefined;

export const initialState: WorkspacesState = localWorkspace
    ? { workspaces: { [localWorkspace.id]: localWorkspace }, selectedId: localWorkspace.id }
    : { workspaces: {}, selectedId: '' };
