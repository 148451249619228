import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated } from '@azure/msal-react';
import { Subtitle1, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Loading, Login } from '../components/views';
import { useWorkspace } from '../libs/hooks';

const useClasses = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
        ...shorthands.overflow('hidden'),
    },
    header: {
        alignItems: 'center',
        backgroundColor: tokens.colorNeutralBackground1,
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0.1rem 0.3rem -0.075rem',
        zIndex: 0,
        color: tokens.colorNeutralForeground1,
        display: 'flex',
        '& h1': {
            paddingLeft: tokens.spacingHorizontalXL,
            display: 'flex',
        },
        height: '48px',
        justifyContent: 'space-between',
        width: '100%',
    },
});

const WorkspaceJoin: React.FC = () => {
    const classes = useClasses();
    const navigate = useNavigate();
    const { workspaceId } = useParams<{ workspaceId: string }>();

    const isAuthenticated = useIsAuthenticated();
    const [error, setError] = useState<string | null>(null);

    const workspace = useWorkspace();

    useEffect(() => {
        if (isAuthenticated) {
            if (workspaceId) {
                void workspace.joinWorkspace(workspaceId).then(({ success, message }) => {
                    if (success) {
                        setTimeout(() => {
                            navigate('/');
                        }, 3000);
                    } else {
                        setError(message);
                    }
                });
            } else {
                setError('No workspace ID was provided.');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <Subtitle1 as="h1">Chat Copilot</Subtitle1>
            </div>
            <UnauthenticatedTemplate>
                <Login />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <Loading text="Joining workspace..." />
                {error && <p style={{ color: 'red' }}>{error}</p>}
            </AuthenticatedTemplate>
        </div>
    );
};

export default WorkspaceJoin;
