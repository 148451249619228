import {
    BrandVariants,
    GriffelStyle,
    Theme,
    createDarkTheme,
    createLightTheme,
    makeStyles,
    shorthands,
    themeToTokensObject,
    tokens,
} from '@fluentui/react-components';
import Color from 'colorjs.io';
import { useEffect, useState } from 'react';
import { useAppSelector } from './redux/app/hooks';
import { RootState } from './redux/app/store';

interface SemanticKernelThemes {
    semanticKernelDarkTheme: Theme & { colorMeBackground: string };
    semanticKernelLightTheme: Theme & { colorMeBackground: string };
}

const createBrandRamp = (color: Color) => {
    const lightness = color.oklch.l;

    return [...Array(16).keys()].reduce<BrandVariants>((acc, curr) => {
        const i = curr + 1;
        let c: Color;

        if (i === 8) {
            c = color;
        } else {
            c = color.clone();
            c.oklch.l = i < 8 ? (lightness / 8) * i : lightness + ((1 - lightness) / 8) * (i - 8);
        }

        acc[(i * 10) as keyof BrandVariants] = c.toString({ format: 'hex' });

        return acc;
        // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
    }, {} as BrandVariants);
};

export const useTheme = () => {
    const { selectedId, workspaces } = useAppSelector((state: RootState) => state.workspaces);
    const [themes, setThemes] = useState<SemanticKernelThemes>({
        semanticKernelDarkTheme,
        semanticKernelLightTheme,
    });

    useEffect(() => {
        if (selectedId) {
            const workspace = workspaces[selectedId];
            const color = new Color(workspace?.branding.primaryColor ?? '#BC627E');
            const brandRamp = createBrandRamp(color);

            setThemes({
                semanticKernelDarkTheme: { ...createDarkTheme(brandRamp), colorMeBackground: '#2b2b3e' },
                semanticKernelLightTheme: { ...createLightTheme(brandRamp), colorMeBackground: '#e8ebf9' },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedId]);

    return themes;
};

const defaultColor = new Color('#BC627E');
export const semanticKernelBrandRamp = createBrandRamp(defaultColor);

export const semanticKernelLightTheme: Theme & { colorMeBackground: string } = {
    ...createLightTheme(semanticKernelBrandRamp),
    colorMeBackground: '#e8ebf9',
};

export const semanticKernelDarkTheme: Theme & { colorMeBackground: string } = {
    ...createDarkTheme(semanticKernelBrandRamp),
    colorMeBackground: '#2b2b3e',
};

export const customTokens = themeToTokensObject(semanticKernelLightTheme);

export const Breakpoints = {
    small: (style: GriffelStyle): Record<string, GriffelStyle> => {
        return { '@media (max-width: 744px)': style };
    },
};

export const ScrollBarStyles: GriffelStyle = {
    overflowY: 'auto',
    '&:hover': {
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: tokens.colorScrollbarOverlay,
            visibility: 'visible',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: tokens.colorNeutralBackground1,
            WebkitBoxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.1)',
            visibility: 'visible',
        },
    },
};

export const SharedStyles: Record<string, GriffelStyle> = {
    scroll: {
        height: '100%',
        ...ScrollBarStyles,
    },
    overflowEllipsis: {
        ...shorthands.overflow('hidden'),
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
};

export const useSharedClasses = makeStyles({
    informativeView: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.padding('80px'),
        alignItems: 'center',
        ...shorthands.gap(tokens.spacingVerticalXL),
        marginTop: tokens.spacingVerticalXXXL,
    },
});

export const useDialogClasses = makeStyles({
    surface: {
        paddingRight: tokens.spacingVerticalXS,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.overflow('hidden'),
        width: '100%',
    },
    paragraphs: {
        marginTop: tokens.spacingHorizontalS,
    },
    innerContent: {
        height: '100%',
        ...SharedStyles.scroll,
        paddingRight: tokens.spacingVerticalL,
    },
    text: {
        whiteSpace: 'pre-wrap',
        textOverflow: 'wrap',
    },
    footer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        minWidth: '175px',
    },
});
