import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated } from '@azure/msal-react';
import {
    Button,
    Caption1,
    Card,
    CardHeader,
    CardPreview,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Input,
    Label,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Subtitle1,
    Text,
    Textarea,
    TextareaProps,
    makeStyles,
    shorthands,
    tokens,
    useRestoreFocusTarget,
} from '@fluentui/react-components';
import { Copy20Regular, Edit20Regular, MoreHorizontal20Regular } from '@fluentui/react-icons';
import React, { useEffect, useState } from 'react';
import { Login } from '../components/views';
import { useWorkspace } from '../libs/hooks';
import { ICreateWorkspace, IWorkspace } from '../libs/models/Workspace';
import { customTokens } from '../styles';

const useClasses = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
        ...shorthands.overflow('hidden'),
    },
    header: {
        alignItems: 'center',
        backgroundColor: tokens.colorNeutralBackground1,
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0.1rem 0.3rem -0.075rem',
        zIndex: 0,
        color: tokens.colorNeutralForeground1,
        display: 'flex',
        '& h1': {
            paddingLeft: tokens.spacingHorizontalXL,
            display: 'flex',
        },
        height: '48px',
        justifyContent: 'space-between',
        width: '100%',
    },
    toolbar: {
        ...shorthands.margin('20px'),
    },
    content: {
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'space-evenly',
        justifyContent: 'space-evenly',
    },
    card: {
        flexBasis: '26%',
        maxWidth: '100%',
        height: 'fit-content',
        ...shorthands.margin('20px'),
    },
    horizontalCardImage: {
        height: '24px',
    },
    caption: {
        color: tokens.colorNeutralForeground3,
    },
});

const Admin: React.FC = () => {
    const classes = useClasses();

    const isAuthenticated = useIsAuthenticated();
    const restoreFocusTargetAttribute = useRestoreFocusTarget();
    const [workspaces, setWorkspaces] = useState<IWorkspace[]>([]);
    const [workspace, setWorkspace] = useState<IWorkspace | ICreateWorkspace | undefined>();

    const service = useWorkspace();

    useEffect(() => {
        if (isAuthenticated) {
            void service.getAllWorkspaces().then(setWorkspaces);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <Subtitle1 as="h1">Chat Copilot</Subtitle1>
            </div>
            <UnauthenticatedTemplate>
                <Login />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <div className={classes.toolbar}>
                    <Button
                        appearance="primary"
                        onClick={() => {
                            setWorkspace({ name: '', branding: { primaryColor: '#BC627E' } });
                        }}
                        aria-label="Create new workspace"
                    >
                        Create new workspace
                    </Button>
                </div>
                <div className={classes.content}>
                    {workspaces.map((w) => (
                        <Card
                            key={w.id}
                            className={classes.card}
                            orientation="horizontal"
                            style={{ border: `1px solid ${w.branding.primaryColor}` }}
                        >
                            <CardPreview className={classes.horizontalCardImage} style={{ padding: '0 10px' }}>
                                <img
                                    className={classes.horizontalCardImage}
                                    src={w.branding.logoUrl ?? 'https://placehold.co/140x24?text=Logo'}
                                    alt={w.name + ' logo'}
                                />
                            </CardPreview>

                            <CardHeader
                                header={<Text weight="semibold">{w.name}</Text>}
                                description={<Caption1 className={classes.caption}>?? bots</Caption1>}
                                action={
                                    <Menu>
                                        <MenuTrigger disableButtonEnhancement>
                                            <MenuButton appearance="transparent" icon={<MoreHorizontal20Regular />} />
                                        </MenuTrigger>
                                        <MenuPopover>
                                            <MenuList>
                                                <MenuItem
                                                    icon={<Edit20Regular />}
                                                    {...restoreFocusTargetAttribute}
                                                    onClick={() => {
                                                        setWorkspace(w);
                                                    }}
                                                >
                                                    Edit
                                                </MenuItem>
                                                <MenuItem
                                                    icon={<Copy20Regular />}
                                                    onClick={() => {
                                                        const joinUrl = new URL(
                                                            `/workspace/join/${w.id}`,
                                                            window.location.href,
                                                        );
                                                        void navigator.clipboard.writeText(joinUrl.toString());
                                                    }}
                                                >
                                                    Copy join URL
                                                </MenuItem>
                                            </MenuList>
                                        </MenuPopover>
                                    </Menu>
                                }
                            />
                        </Card>
                    ))}
                </div>
                <WorkspaceDialog
                    workspace={workspace}
                    setWorkspace={(workspace) => {
                        if (workspace) {
                            setWorkspaces((workspaces) => {
                                const index = workspaces.findIndex((w) => w.id === workspace.id);

                                if (index !== -1) {
                                    workspaces[index] = workspace;
                                } else {
                                    workspaces.push(workspace);
                                }

                                return workspaces;
                            });
                        }

                        setWorkspace(undefined);
                    }}
                />
            </AuthenticatedTemplate>
        </div>
    );
};

const useDialogClasses = makeStyles({
    content: {
        display: 'flex',
        flexDirection: 'column',

        '& > label, & > div': {
            marginTop: tokens.spacingVerticalM,
        },

        '& textarea': {
            height: '100px',
        },
    },
    logoImage: {
        height: '24px',
        ...shorthands.padding('8px'),
        ...shorthands.border('1px', 'solid', 'rgb(0 0 0 / 10%)'),
        ...shorthands.borderRadius(customTokens.borderRadiusMedium),
    },
});

interface IWorkspaceDialogProps {
    workspace?: IWorkspace | ICreateWorkspace;
    setWorkspace: (workspace?: IWorkspace) => void;
}

const WorkspaceDialog: React.FC<IWorkspaceDialogProps> = ({ workspace, setWorkspace }) => {
    const classes = useDialogClasses();

    const service = useWorkspace();

    const fileRef = React.useRef<HTMLInputElement>(null);
    const [logoUrl, setLogoUrl] = useState<string>('');

    useEffect(() => {
        if (workspace) {
            setLogoUrl(workspace.branding.logoUrl ?? '');
        }
    }, [workspace]);

    const onLogoUrlEntry: TextareaProps['onChange'] = (_, data) => {
        setLogoUrl(data.value);
    };

    const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = new FormData(e.currentTarget);
        const wsName = data.get('name')?.toString();
        const wsPrimaryColor = data.get('primaryColor')?.toString();
        const wsLogoUrl = data.get('logoUrl')?.toString();

        if (wsName && wsPrimaryColor) {
            const branding = { primaryColor: wsPrimaryColor, logoUrl: wsLogoUrl === '' ? undefined : wsLogoUrl };
            const request =
                workspace && 'id' in workspace
                    ? service.editWorkspace(workspace.id, wsName, branding)
                    : service.createWorkspace(wsName, branding);

            void request.then((response) => {
                if (response.success) {
                    console.log(response.result);
                    setWorkspace(response.result);
                }
            });
        }
    };

    return (
        <Dialog
            open={!!workspace}
            onOpenChange={() => {
                setWorkspace();
            }}
        >
            <DialogSurface>
                <form onSubmit={handleSave}>
                    <DialogBody>
                        <DialogTitle>{workspace && 'id' in workspace ? 'Edit' : 'Create'} workspace</DialogTitle>

                        <DialogContent className={classes.content}>
                            <Label required htmlFor={'name'}>
                                Name
                            </Label>
                            <Input
                                required
                                type="text"
                                name={'name'}
                                defaultValue={workspace?.name}
                                placeholder="Enter workspace name"
                            />
                            <Label htmlFor={'primaryColor'}>Brand colour</Label>
                            <input
                                required
                                type="color"
                                name={'primaryColor'}
                                defaultValue={workspace?.branding.primaryColor}
                            />
                            <div>
                                <img
                                    src={logoUrl === '' ? 'https://placehold.co/140x24?text=Logo' : logoUrl}
                                    alt={workspace?.name + ' logo'}
                                    className={classes.logoImage}
                                />
                            </div>
                            <input
                                type="file"
                                ref={fileRef}
                                onChange={() => {
                                    const file = fileRef.current?.files?.[0];

                                    if (file) {
                                        const reader = new FileReader();

                                        reader.onloadend = () => {
                                            setLogoUrl(reader.result as string);
                                        };

                                        reader.readAsDataURL(file);
                                    }
                                }}
                            />
                            <Textarea
                                name={'logoUrl'}
                                value={logoUrl}
                                onChange={onLogoUrlEntry}
                                placeholder="Consider using https://tinypng.com/ to reduce file size."
                            />
                        </DialogContent>

                        <DialogActions>
                            <DialogTrigger disableButtonEnhancement>
                                <Button appearance="secondary">Cancel</Button>
                            </DialogTrigger>
                            <Button appearance="primary" type="submit">
                                Save
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </form>
            </DialogSurface>
        </Dialog>
    );
};

export default Admin;
